import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { getSubscriberApiAsync } from '../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';
import { excelIcon, excelIcon1, excelIcon2, excelImg } from '../../../utilities/images';
import moment from 'moment-timezone';
import DashboardNavbar from '../dashboard/DashboardNavbar';

const Subscriber = () => {
  const [loading, setLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [subscriberData, setSubscriberData] = useState([])
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch()

  const downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  }

  const columns1 = [
    {
      name: '#',
      selector: (row, i) => (i + 1 * (perPage * (currentPage - 1)) + 1),
      width: "100px"
    },
    {
      name: 'Full Name',
      selector: row => {
        return (
          <>
            <span className='textParagh'>{row?.fullname}</span>
          </>
        );
      },
      // width: "150px",
    },
    {
      name: 'Email',
      selector: row => {
        return (
          <>
            <span className='textParagh'>{row?.email}</span>
          </>
        );
      },
      // width: "400px",
    },
    {
      name: 'Business Type',
      selector: row => {
        return (
          <>
            <span className='textParagh'>{row?.business_type == "retail" ? "Retail" : row?.business_type == "service_provider" ? "Service Provider" : "Wholesaler/distributor"}</span>
          </>
        );
      },
      // width: "400px",
    },
    {
      name: 'Created at',
      selector: row => {
        return (
          <>
            <span className='textParagh'>{moment(row?.created_at).format("MMM DD, YYYY")}</span>
          </>
        );
      },
      // width: "150px",
    }
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };

  const getSubscriberData = () => {
    setLoading(true)
    let params = {
      page: currentPage,
      limit: perPage
    }
    dispatch(getSubscriberApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        setSubscriberData(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
      })
      .catch((obj) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getSubscriberData();
  }, [currentPage, perPage])

  return (
    <>
      <DashboardNavbar title="Subscriber" backShow={false} />
      <div className='productList subscriberList_' >
            <div className="row align-items-center">
              <div className="col-md-6">
              </div>
              <div className="col-md-6 text-end">
                <button className='backgroundFillbtn' onClick={() => downloadExcel(subscriberData)}>
                  <span>Download</span><img style={{ width: "20px", margin: "0px 0px 0px 10px" }} src={excelImg} alt='' /></button>
              </div>
            </div>

        <section className='productListView table-responsive mt-3'>
            <DataTable
              className=''
              columns={columns1}
              data={subscriberData}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={perPage}
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
        </section>
      </div>
    </>
  )
}

export default Subscriber